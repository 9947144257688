.app-chat {
    position: relative;
    height: calc(100vh - 11rem)
}

@media(min-width: 1200px) {
    .layout-horizontal .app-chat {
        height:calc(100vh - 11rem - 2rem)
    }
}

@media(max-width: 992px) {
    .app-chat .app-sidebar {
        z-index:4
    }
}

.app-chat .app-sidebar .sidebar-header {
    position: relative
}

.app-chat .app-sidebar .sidebar-header .close-sidebar {
    position: absolute;
    top: .5rem;
    right: .95rem
}

.app-chat .app-chat-contacts {
    position: absolute;
    left: calc(-21rem - 1rem);
    height: calc(100vh - 11rem);
    width: 21rem;
    flex-basis: 21rem;
    transition: all .25s ease
}

@media(min-width: 1200px) {
    .layout-horizontal .app-chat .app-chat-contacts {
        height:calc(100vh - 11rem - 2rem)
    }
}

@media(min-width: 992px) {
    .app-chat .app-chat-contacts {
        position:static
    }
}

.app-chat .app-chat-contacts.show {
    left: 0rem
}

.app-chat .app-chat-contacts .sidebar-body {
    height: calc(calc(100vh - 11rem) - 4.49rem)
}

@media(min-width: 1200px) {
    .layout-horizontal .app-chat .app-chat-contacts .sidebar-body {
        height:calc(calc(100vh - 11rem) - 4.49rem - 2rem)
    }
}

.app-chat .app-chat-contacts .sidebar-body .chat-contact-list .chat-contact-list-item-title {
    padding-bottom: 0 !important
}

.app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.chat-contact-list-item {
    display: flex;
    justify-content: space-between;
    padding: 2px;
    margin-right: .5rem;
    border-left: 2px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    border-radius: 8px;
}

.app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.chat-contact-list-item a {
    width: 100%
}

.app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.chat-contact-list-item .avatar {
    border: 2px solid rgba(0,0,0,0);
    border-radius: 50%
}

.app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.chat-contact-list-item .chat-contact-info {
    min-width: 0
}

.app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.chat-contact-list-item .chat-contact-info .chat-contact-name {
    line-height: 1.5
}

.app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.chat-contact-list-item small {
    white-space: nowrap
}

.app-chat .app-chat-sidebar-left {
    position: absolute;
    top: 0;
    left: calc(-21rem - 1rem);
    width: 21rem;
    height: calc(100vh - 11rem);
    opacity: 0;
    z-index: 5;
    transition: all .25s ease
}

@media(min-width: 1200px) {
    .layout-horizontal .app-chat .app-chat-sidebar-left {
        height:calc(100vh - 11rem - 2rem)
    }
}

.app-chat .app-chat-sidebar-left.show {
    left: 0;
    opacity: 1
}

.app-chat .app-chat-sidebar-left .sidebar-body {
    height: calc(calc(100vh - 11rem) - 11.7rem)
}

@media(min-width: 1200px) {
    .layout-horizontal .app-chat .app-chat-sidebar-left .sidebar-body {
        height:calc(calc(100vh - 11rem) - 11.7rem - 2rem)
    }
}

.app-chat .app-chat-history {
    position: relative;
    height: calc(100vh - 11rem);
    transition: all .25s ease
}

@media(min-width: 1200px) {
    .layout-horizontal .app-chat .app-chat-history {
        height:calc(100vh - 11rem - 2rem)
    }
}

.app-chat .app-chat-history .chat-history-header {
    padding: 1rem 1rem
}

.app-chat .app-chat-history .chat-history-header .user-status {
    margin-bottom: .1rem
}

.app-chat .app-chat-history .chat-history-body {
    height: calc(100vh - 18.8rem);
    padding: 1.25rem 1.25rem;
    overflow: hidden
}

@media(min-width: 1200px) {
    .layout-horizontal .app-chat .app-chat-history .chat-history-body {
        height:calc(100vh - 20.8rem - 2rem)
    }
}

.app-chat .app-chat-history .chat-history-body .chat-history .chat-message {
    display: flex;
    justify-content: flex-start
}

.app-chat .app-chat-history .chat-history-body .chat-history .chat-message .chat-message-text {
    border-radius: .375rem;
    padding: .75rem 1rem
}

.app-chat .app-chat-history .chat-history-body .chat-history .chat-message:not(.chat-message-right) .chat-message-text {
    border-top-left-radius: 0
}

.app-chat .app-chat-history .chat-history-body .chat-history .chat-message.chat-message-right {
    justify-content: flex-end
}

.app-chat .app-chat-history .chat-history-body .chat-history .chat-message.chat-message-right .chat-message-text {
    border-top-right-radius: 0
}

.app-chat .app-chat-history .chat-history-body .chat-history .chat-message.chat-message-right .user-avatar {
    margin-right: 0rem;
    margin-left: 1rem
}

.app-chat .app-chat-history .chat-history-body .chat-history .chat-message .thumbnail {
    cursor: zoom-in
}

.app-chat .app-chat-history .chat-history-body .chat-history .chat-message:not(:last-child) {
    margin-bottom: 1rem
}

.app-chat .app-chat-history .chat-history-footer {
    padding: .5rem .5rem;
    margin: 0 1.5rem;
    border-radius: .375rem
}

.app-chat .app-chat-sidebar-right {
    position: absolute;
    top: 0;
    right: calc(-21rem - 1rem);
    width: 21rem;
    height: calc(100vh - 11rem);
    opacity: 0;
    z-index: 5;
    transition: all .25s ease
}

@media(min-width: 1200px) {
    .layout-horizontal .app-chat .app-chat-sidebar-right {
        height:calc(100vh - 11rem - 2rem)
    }
}

.app-chat .app-chat-sidebar-right.show {
    opacity: 1;
    right: 0
}

.app-chat .app-chat-sidebar-right .sidebar-body {
    height: calc(calc(100vh - 11rem) - 11.5rem)
}

@media(min-width: 1200px) {
    .layout-horizontal .app-chat .app-chat-sidebar-right .sidebar-body {
        height:calc(calc(100vh - 11rem) - 11.5rem - 2rem)
    }
}

@media(max-width: 576px) {
    .app-chat .app-chat-sidebar-right.show,.app-chat .app-chat-sidebar-left.show,.app-chat .app-chat-contacts.show {
        width:100%
    }
}

.light-style .app-chat .app-chat-contacts,.light-style .app-chat .app-chat-sidebar-left {
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(67,89,113,.075)
}

.light-style .app-chat .app-chat-contacts .chat-actions .chat-search-input,.light-style .app-chat .app-chat-sidebar-left .chat-actions .chat-search-input {
    background-color: #f5f5f9
}

.light-style .app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.active,.light-style .app-chat .app-chat-sidebar-left .sidebar-body .chat-contact-list li.active {
    color: #fff
}

.light-style .app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.active h6,.light-style .app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.active .text-muted,.light-style .app-chat .app-chat-sidebar-left .sidebar-body .chat-contact-list li.active h6,.light-style .app-chat .app-chat-sidebar-left .sidebar-body .chat-contact-list li.active .text-muted {
    color: #fff !important
}

.light-style .app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.active .avatar,.light-style .app-chat .app-chat-sidebar-left .sidebar-body .chat-contact-list li.active .avatar {
    border-color: #fff
}

.light-style .app-chat .app-chat-history {
    background-color: #f5f5f9
}

.light-style .app-chat .app-chat-history .chat-history-wrapper .chat-history-header {
    background-color: #fff
}

.light-style .app-chat .app-chat-history .chat-history-footer {
    background-color: #fff;
    box-shadow: 0 .125rem .25rem rgba(161,172,184,.4)
}

.light-style .app-chat .app-chat-history .chat-history-body .chat-history .chat-message .chat-message-text {
    background-color: #fff;
    box-shadow: 0 .125rem .25rem rgba(161,172,184,.4)
}

.light-style .app-chat .app-chat-history .chat-history-body .chat-history .chat-message.chat-message-right {
    justify-content: flex-end
}

.light-style .app-chat .app-chat-history .chat-history-body .chat-history .chat-message.chat-message-right .chat-message-text {
    color: #fff
}

.light-style .app-chat .app-chat-sidebar-right {
    background-color: #fff;
    box-shadow: 16px 1px 45px 3px rgba(67,89,113,.5)
}

@media(max-width: 992px) {
    .light-style .app-chat .app-chat-contacts .chat-actions .chat-search-input {
        background-color:#fff
    }
}

.dark-style .app-chat .app-chat-contacts,.dark-style .app-chat .app-chat-sidebar-left {
    background-color: #2b2c40
}

.dark-style .app-chat .app-chat-contacts .chat-actions .chat-search-input,.dark-style .app-chat .app-chat-sidebar-left .chat-actions .chat-search-input {
    background-color: #232333
}

.dark-style .app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.active,.dark-style .app-chat .app-chat-sidebar-left .sidebar-body .chat-contact-list li.active {
    color: #fff
}

.dark-style .app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.active h6,.dark-style .app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.active .text-muted,.dark-style .app-chat .app-chat-sidebar-left .sidebar-body .chat-contact-list li.active h6,.dark-style .app-chat .app-chat-sidebar-left .sidebar-body .chat-contact-list li.active .text-muted {
    color: #fff !important
}

.dark-style .app-chat .app-chat-contacts .sidebar-body .chat-contact-list li.active .avatar,.dark-style .app-chat .app-chat-sidebar-left .sidebar-body .chat-contact-list li.active .avatar {
    border-color: #2b2c40
}

.dark-style .app-chat .app-chat-history {
    background-color: #232333
}

.dark-style .app-chat .app-chat-history .chat-history-wrapper .chat-history-header {
    background-color: #2b2c40
}

.dark-style .app-chat .app-chat-history .chat-history-footer {
    background-color: #2b2c40;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.25)
}

.dark-style .app-chat .app-chat-history .chat-history-body .chat-history .chat-message .chat-message-text {
    background-color: #2b2c40;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.25)
}

.dark-style .app-chat .app-chat-history .chat-history-body .chat-history .chat-message.chat-message-right {
    justify-content: flex-end
}

.dark-style .app-chat .app-chat-history .chat-history-body .chat-history .chat-message.chat-message-right .chat-message-text {
    color: #fff
}

.dark-style .app-chat .app-chat-sidebar-right {
    background-color: #2b2c40
}

[dir=rtl] .app-chat .app-chat-sidebar-left,[dir=rtl] .app-chat .app-chat-contacts {
    right: calc(-21rem - 1rem);
    left: auto
}

[dir=rtl] .app-chat .app-chat-sidebar-left.show,[dir=rtl] .app-chat .app-chat-contacts.show {
    left: auto;
    right: 0
}

[dir=rtl] .app-chat .app-chat-sidebar-right {
    left: calc(-21rem - 1rem);
    right: auto
}

[dir=rtl] .app-chat .app-chat-sidebar-right.show {
    left: 0;
    right: auto
}

[dir=rtl] .app-chat .app-chat-history .chat-history-body .chat-history .chat .user-avatar {
    margin-left: 1rem;
    margin-right: 0
}

[dir=rtl] .app-chat .app-chat-history .chat-message:not(.chat-message-right) .chat-message-text {
    border-top-right-radius: 0;
    border-top-left-radius: .375rem !important
}

[dir=rtl] .app-chat .app-chat-history .chat-message.chat-message-right .chat-message-text {
    border-top-left-radius: 0;
    border-top-right-radius: .375rem !important
}
